import React from 'react';
import Modal from '../Modal';
import Hero from './Hero';
import List from './List';

const Home = () => {
  return (
    <div>
      <Modal />
      <Hero />
      <List />
    </div>
  );
};

export default Home;
