import React, { useState } from 'react';
import Modal from 'react-modal';
import {
  Container,
  LeftPart,
  RightPart,
  FormWrapper,
  CallToAction,
  InputWrapper,
  Input,
  Button
} from './Modal.module.css';


const ModalStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)'
  }
};

const MyModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isNew, SetIsNew] = useState(true);

  const onOpen = () => {
    setIsOpen(true);
  }

  const onClose = () => {
    setIsOpen(false);
  }

  const openModal = () => {
    setIsOpen(true);
  }

  if (isNew) {
    setTimeout(openModal, 5000);
    SetIsNew(false);
  }

  return (
    <div>
      <Modal
        isOpen={isOpen}
        onAfterOpen={onOpen}
        onRequestClose={onClose}
        style={ModalStyle}
        contentLabel='Modal'
        ariaHideApp={false}
      >
        <div className={Container}>
          {/* <div className={LeftPart}> */}
            {/* <img src='/images/list-book.png' alt='La liste des 178 idees de start-up tech' /> */}
          {/* </div> */}
          {/* <div className={RightPart}> */}
            <div className={FormWrapper}>
              <form className={CallToAction} action="https://lancetoietcode.us2.list-manage.com/subscribe/post?u=df4d0a3d0404e2aa527fa4e62&amp;id=378b1254bc" method="post" target="_blank" noValidate>
                <span>Reçois la liste des 178 idées<br ></br>de start-up tech !</span>
                <p>
                  Tu veux te lancer mais tu n'as pas d'idées ?
                  <br />
                  Tu ne sais pas par où commencer ?
                  <br />
                  Dans cette liste de 178 idées, tu trouveras forcément une idée<br />pour te lancer et commencer dès aujourd'hui ton projet !
                </p>
                <div className={InputWrapper}>
                  <input type="email" name="EMAIL" placeholder="prenom.nom@email.fr" className={Input} />
                  <input type="submit" value="Recevoir la checklist !" className={Button}/>
                </div>
              </form>
            </div>
          {/* </div> */}
        </div>
      </Modal>
    </div>
  );
};

export default MyModal;
