/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { useParams } from 'react-router-dom';
import {
  Container,
  BackgroundWrapper,
  HeaderWrapper,
  OverTitle,
  Title,
  TypeTag,
  SubTitle,
  SubTitleLink,
} from './Header.module.css';

import episodes from '../episodes.js';

const type = {
  TEASER: 0,
  INTERVIEW: 1,
  JDB: 2
};

const typeName = [
  'Teaser',
  'Interview',
  'Journal De Bord'
];

const Header = () => {
  const { episodeId } = useParams();
  const episode = episodes[episodeId];

  return (
    <div className={Container}>
      <div className={BackgroundWrapper}></div>
      <div className={HeaderWrapper}>
        <span className={OverTitle}>
          { episode.id > 26 ? 'Saison 2' : 'Saison 1' }
          <span> - </span>
          Episode {episode.id > 26 ? episode.id % 26 : episode.id}
        </span>
        <h1 className={Title}>{episode.title}</h1>
        <span className={TypeTag}>{typeName[episode.type]}</span>
        {
          episode.type === type.INTERVIEW ?
            <h2 className={SubTitle}>
              <p>
                Avec {episode.guestList.map((guest, guestIndex) => (
                  <span guestIndex={guestIndex}>
                    <b>{guest.name}</b> de {guest.linkList.map((link, linkIndex) => (
                      <span linkIndex={linkIndex}>
                        <a href={link.url} target="_blank" rel="noopener" className={SubTitleLink}><i>{link.name}</i></a>
                        {
                          linkIndex < guest.linkList.length - 1 ?
                            <>, </>
                          :
                            <></>
                        }
                      </span>
                    ))}
                    {
                      guestIndex < episode.guestList.length - 1 ?
                        <>, </>
                      :
                        <></>
                    }
                  </span>
                ))}
              </p>
            </h2>
          :
            <></>
        }
      </div>
    </div>
  );
};

export default Header;
