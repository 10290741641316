import React from 'react';
import { useParams } from 'react-router-dom';
import {
  Container,
  BackgroundWrapper,
  BodyWrapper,
  Listen,
  SectionTitle,
  Iframe,
  PlatformListen,
  Platform,
  Description,
} from './Body.module.css';

import episodes from '../episodes.js';

const Body = () => {
  const { episodeId } = useParams();
  const episode = episodes[episodeId];

  const redirect = (url, newWindow) => {
    if (newWindow) {
      return (window.open(url));
    }
    return (window.location.href = url);
  }

  return (
    <div className={Container}>
      <div className={BackgroundWrapper}></div>
      <div className={BodyWrapper}>
        <div className={Listen}>
          <h2 className={SectionTitle}>Ecoutez l'épisode</h2>
          <div>
            <iframe src={episode.embededUrl} height="102px" width="400px" frameBorder="0" scrolling="no" title="Episode" className={Iframe}></iframe>
          </div>
          <div className={PlatformListen}>
            <div className={Platform} onClick={() => redirect(episode.platform.spotify, true)}>
              <img src="/images/listen-on-spotify.png" height="50px" alt="" />
            </div>
            <div className={Platform} onClick={() => redirect(episode.platform.applePodcast, true)}>
              <img src="/images/listen-on-applepodcast.png" height="50px" alt="" />
            </div>
            <div className={Platform} onClick={() => redirect(episode.platform.castbox, true)}>
              <img src="/images/listen-on-castbox.png" height="50px" alt="" />
            </div>
          </div>
        </div>
        <div className={Description}>
          <h2 className={SectionTitle}>Description de l'épisode</h2>
          <div>
            {episode.description.split('\n').map((line, lineIndex) => (
              <p key={lineIndex}>{line}</p>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Body;
