const episodes = [
  {
    "id": 0,
    "title": "Episode 00 - Présentation du podcast",
    "description": "Dans Lance Toi Et Code on parle de Tech, d’Entrepreneuriat, et de saucisson, mais on parle aussi de réussite, et d'échec, mais surtout on parle de comment créer un produit rentable, gérer l’administratif et rester motivé sur le long terme.",
    "type": 0,
    "platform": {
      "spotify": "https://open.spotify.com/episode/76cQbjusYV2Lb3hYrIhEPT",
      "applePodcast": "https://podcasts.apple.com/fr/podcast/lance-toi-et-code/id1535757097",
      "castbox": "https://castbox.fm/episode/Episode-00---Pr%C3%A9sentation-du-podcast-id3433042-id335955196"
    },
    "embededUrl": "https://anchor.fm/lancetoietcode/embed/episodes/Episode-00---Prsentation-du-podcast-engh3i"
  },
  {
    "id": 1,
    "title": "#01 - L’idée à 1 milliard, ou un milliard d’idées ?",
    "description": "Votre idée de start-up vaut un milliard 💰💰💰 … ou pas ! 🤷‍♀️\n\nQuand on veut entreprendre, on cherche l’idée à un milliard. Sauf qu’elles se cachent bien ces idées. 👀\n\nSoit on en a une nouvelle tous les jours et on n'arrive pas à choisir, soit on n’arrive pas à en trouver une seule! 🙃\n\nAlors, comment ont fait les entrepreneurs à succès pour trouver leur idée ? 🤔\n\nOn répondra à cette question et bien d’autres dans le premier épisode de Lance toi et Code.\n\nDans cet épisode, on accueillera Mathieu Nohet, ingénieur et développeur de formation devenu cofondateur et PDG de Manty.\n\nMathieu va aborder son parcours, comment lui et ses cofondateurs ont eu l’idée de se lancer dans le secteur public, et comment son passé de développeur l’a aidé dans son aventure.",
    "type": 1,
    "platform": {
      "spotify": "https://open.spotify.com/episode/4a4CJCZtvawLu3UB4MwQPw",
      "applePodcast": "https://podcasts.apple.com/fr/podcast/lance-toi-et-code/id1535757097",
      "castbox": "https://castbox.fm/episode/01---L%E2%80%99id%C3%A9e-%C3%A0-1-milliard%2C-ou-un-milliard-d%E2%80%99id%C3%A9es---id3433042-id335955198"
    },
    "embededUrl": "https://anchor.fm/lancetoietcode/embed/episodes/01---Lide--1-milliard--ou-un-milliard-dides-enoolp",
    "guestList": [
      {
        "name": "Mathieu Nohet",
        "linkList": [
          {
            "name": "Manty",
            "url": "https://www.manty.eu/"
          }
        ]
      }
    ]
  },
  {
    "id": 2,
    "title": "#02 - Dites \"No\" au code !",
    "description": "En tant que développeur et entrepreneur, taper du code est une erreur 🤦‍♀️ (du moins au début).\n\nEt oui on est fou comme ça. 🙃\n\nOn sait que vous avez envie de vous lancer, fermer les volets, commander 20L de redbull sur amazon et de passer les 6 prochains mois à taper du code pour donner vie à votre bébé. 💻\n\nEt pourquoi pas ? Votre idée elle déchire, vous en êtes sûr ! 👊\n\nSauf que dans 6 mois lorsque personne n’achètera votre logiciel, ça va piquer. 💉\n\nAlors comment faire pour savoir si notre produit va plaire, avant même de commencer à coder ?\n\nOn répondra à cette question et bien d’autres dans le deuxième épisode de Lance toi et Code.\n\nDans cet épisode, on accueillera ♠️Thibault Marty, fondateur et PDG d’Ottho, qui va nous expliquer que pour vite tester son idée, il vaut mieux ne pas taper de code.",
    "type": 1,
    "platform": {
      "spotify": "https://open.spotify.com/episode/0LtwhgDiJyfmeJ6oabv7hF",
      "applePodcast": "https://podcasts.apple.com/fr/podcast/lance-toi-et-code/id1535757097?l=en&i=1000502387836",
      "castbox": "https://castbox.fm/episode/02---Dites-%22No%22-au-code-!-id3433042-id336445040?country=gb"
    },
    "embededUrl": "https://anchor.fm/lancetoietcode/embed/episodes/02---Dites-No-au-code-enqdjf",
    "guestList": [
      {
        "name": "Thibault Marty",
        "linkList": [
          {
            "name": "Ottho",
            "url": "https://ottho.fr/"
          }
        ]
      }
    ]
  },
  {
    "id": 3,
    "title": "#03 - Rester motivé, même quand on perd 1 million d'euros",
    "description": "Comment réagiriez-vous si vous perdiez un contrat à 1 millions d’euros ?😱\n\nEntreprendre c’est des hauts et des bas. Beaucoup de bas même!\n\nLes challenges sont incessants, les obstacles quotidiens, et la solitude rôde à chaque coin de pivot.\n\nUn projet qui est excitant et motivant au premier jour, perd de sa superbe au fil des mois et des années. 😥\n\nAlors comment les entrepreneurs font-ils pour tenir la distance ?\n\nDans cet épisode, on accueillera Guillaume Faia, fondateur et dirigeant de Honikou Games.\n\nAvant de diriger un studio qui développe régulièrement des jeux #1 sur l’app store dans de nombreux pays, Guillaume a dû surmonter d’innombrables obstacles.\n\nGuillaume va nous expliquer comment surmonter les échecs, comment rester motiver pendant plus de 6 ans malgré les obstacles, et il nous parlera de son parcours qui, on l'espère, vous inspirera à vous dépasser.",
    "type": 1,
    "platform": {
      "spotify": "https://open.spotify.com/episode/2E0KJzl8hfwxlSmWDBWemm",
      "applePodcast": "https://podcasts.apple.com/fr/podcast/lance-toi-et-code/id1535757097",
      "castbox": "https://castbox.fm/episode/03---Rester-motiv%C3%A9%2C-m%C3%AAme-quand-on-perd-1-million-d'euros-id3433042-id336445042"
    },
    "embededUrl": "https://anchor.fm/lancetoietcode/embed/episodes/03---Rester-motiv--mme-quand-on-perd-1-million-deuros-enqeev",
    "guestList": [
      {
        "name": "Guillaume Faia",
        "linkList": [
          {
            "name": "Honikou Games",
            "url": "https://honikougames.com/"
          }
        ]
      }
    ]
  },
  {
    "id": 4,
    "title": "#04 - Comment naviguer dans la jungle administrative ?",
    "description": "Les procédures administratives pour créer une entreprise, ça facilite la vie. 😉\n\nCa peut paraître contre-intuitif, mais c’est comme dans l’aviation. ✈\n\nLes règles de sécurité de l’aviation permettent aux pilotes de se concentrer uniquement sur le vol de l'appareil.\n\nDe même, les démarches administratives de la création d’entreprise créent un cadre qui permet à l’entrepreneur de se concentrer sur son business. 👊\n\nMais ces démarches administratives font peur: il faut déclarer son statut, remplir de la paperasse, s’assurer que tout est bien fait... 😱\n\nPar où commencer ? Quel statut d’entreprise choisir ?\n\nDans cet épisode, on accueillera Estelle Zeliszewski, content manager chez Shine.\n\nEstelle va répondre à toutes les questions que l’on se pose sur les démarches administratives lorsque l’on monte une entreprise.",
    "type": 1,
    "platform": {
      "spotify": "https://open.spotify.com/episode/76pwu1EskDtBsxahJYHbcu",
      "applePodcast": "https://podcasts.apple.com/fr/podcast/lance-toi-et-code/id1535757097",
      "castbox": "https://castbox.fm/episode/04---Comment-naviguer-dans-la-jungle-administrative---id3433042-id336636736"
    },
    "embededUrl": "https://anchor.fm/lancetoietcode/embed/episodes/04---Comment-naviguer-dans-la-jungle-administrative-enqvdd",
    "guestList": [
      {
        "name": "Estelle Zeliszewski",
        "linkList": [
          {
            "name": "Shine",
            "url": "https://www.shine.fr/"
          }
        ]
      }
    ]
  },
  {
    "id": 5,
    "title": "#05 - VC et Champagne: Comment et pourquoi lever des fonds",
    "description": "Vous n’avez pas besoin des VC pour monter une entreprise. 🙃\n\nMais en avez vous besoin pour créer une licorne ? 🤔\n\nPour monter une entreprise, il faut du capital: il faut bien payer ses serveurs, ses employés, et la table de babyfoot dans les bureaux. 😁\n\nAu début on met l’argent de son livret A, et pour les plus chanceux, le chèque de l’oncle qui a bien voulu financer votre rêve de création d’entreprise. 💲💲💲\n\nMais il vient un moment où ce n’est plus suffisant. 🙅‍♂️\n\nAlors peut-on éviter de vendre des parts de notre entreprise ?\nEst-ce qu’il faut pour ça revoir nos ambitions de croissance à la baisse ? 🤷‍♂️\n\nDans cet épisode, on accueillera Victoire Laurenty, Romain Salzman et Clement Renault qui vont nous aider à comprendre comment le capital risque peut constituer une solution viable à vos besoins de capital.",
    "type": 1,
    "platform": {
      "spotify": "https://open.spotify.com/episode/6kcCxpYLsUVfaMONcKOz8b",
      "applePodcast": "https://podcasts.apple.com/fr/podcast/lance-toi-et-code/id1535757097",
      "castbox": "https://castbox.fm/episode/05---VC-et-Champagne%3A-Comment-et-pourquoi-lever-des-fonds-id3433042-id336636737"
    },
    "embededUrl": "https://anchor.fm/lancetoietcode/embed/episodes/05---VC-et-Champagne-Comment-et-pourquoi-lever-des-fonds-enr02t",
    "guestList": [
      {
        "name": "Clément Renault",
        "linkList": [
          {
            "name": "Louve Invest",
            "url": "https://www.louveinvest.com/"
          }
        ]
      }, {
        "name": "Victoire Laurenty",
        "linkList": [
          {
            "name": "Kerala Ventures",
            "url": "https://www.kerala.vc/"
          }
        ]
      }, {
        "name": "Romain Salzman",
        "linkList": [
          {
            "name": "Flashbreak",
            "url": "https://apps.apple.com/fr/app/flashbreak-live-quiz-show/id1316078359"
          }
        ]
      }
    ]
  },
  {
    "id": 6,
    "title": "#06 - Créer sa boite sans bureaux, sans employés, et sans VC",
    "description": "Lancer une start-up pour en faire un licorne c’est pas pour tout le monde.\n\nLes licornes c’est cool, mais certains préfèrent les chameaux.\n\nEn effet, le problème aujourd’hui c’est que le modèle prédominant lorsque l’on parle de création d’entreprise tech, c’est le modèle des start-ups à l’américaine: Gros risque, gros chèques de VC, gros épuisement.\n\nEt beaucoup font l’erreur de se dire que c’est le seul modèle valable. Mais c’est faux !\n\nPour ceux qui veulent créer des chameaux, on accueille dans cet épisode Anthony Heukmes du podcast The Mature Dev qui va nous montrer une méthode alternative à la création d’entreprise: La méthode des Mature Dev.\n\nPour apprendre comment créer une boite sans bureaux, sans employés et sans VC, rdv sur Spotify, Apple podcast ou notre site web (liens en commentaire).",
    "type": 1,
    "platform": {
      "spotify": "https://open.spotify.com/episode/2mk5E7vub61xAcAfHK5dMt",
      "applePodcast": "https://podcasts.apple.com/fr/podcast/lance-toi-et-code/id1535757097",
      "castbox": "https://castbox.fm/episode/06---Cr%C3%A9er-sa-boite-sans-bureaux%2C-sans-employ%C3%A9s%2C-et-sans-VC-id3433042-id346688479"
    },
    "embededUrl": "https://anchor.fm/lancetoietcode/embed/episodes/06---Crer-sa-boite-sans-bureaux--sans-employs--et-sans-VC-ep5730",
    "guestList": [
      {
        "name": "Anthony Heukmes",
        "linkList": [
          {
            "name": "The Mature Dev",
            "url": "https://themature.dev/"
          }
        ]
      }
    ]
  },
  {
    "id": 7,
    "title": "#07 - Comment dire à ses utilisateurs gratuits, qu'ils vont devoir payer ?",
    "description": "Une entreprise sans business model rentable, c’est juste une pompe à cash.\n\nSauf que trouver son business model c’est dur: Non seulement il faut trouver le meilleur moyen de monétiser sa solution, mais il faut aussi s’assurer qu’elle génère plus d’argent qu’il n’en coûte à la produire et la vendre.\n\nAussi, contrairement à la création d’un produit, il est beaucoup plus difficile d’itérer sur son business model: Vous imaginez dire à vos clients “Ah c’était gratuit, mais en fait maintenant c’est payant” ?!\n\nAlors comment faire pour trouver le bon business model pour son entreprise ?\n\nComment font les start-ups qui ne sont pas rentables dès le début ?\n\nPeut-on changer de business model en cours de route ?\n\nDans l’épisode de cette semaine on accueille Remi Duvoux, cofondateur et PDG d’Okarito, une start-up qui révolutionne les voyages d'affaires.",
    "type": 1,
    "platform": {
      "spotify": "https://open.spotify.com/episode/0klwklKMwLdGhuMDkF7uv2",
      "applePodcast": "https://podcasts.apple.com/fr/podcast/lance-toi-et-code/id1535757097",
      "castbox": "https://castbox.fm/episode/07---Comment-dire-%C3%A0-ses-utilisateurs-gratuits%2C-qu'ils-vont-devoir-payer---id3433042-id348332646"
    },
    "embededUrl": "https://anchor.fm/lancetoietcode/embed/episodes/07---Comment-dire--ses-utilisateurs-gratuits--quils-vont-devoir-payer-epfdaa",
    "guestList": [
      {
        "name": "Remi Duvoux",
        "linkList": [
          {
            "name": "Okarito",
            "url": "https://www.okarito.io/"
          }
        ]
      }
    ]
  },
  {
    "id": 8,
    "title": "#08 - Quête de cash ou quête de sens ?",
    "description": "Aujourd’hui dans lance toi et code, on se demande si on peut lier quête de sens et quête d’argent.\n\nFatigués de leur train-train quotidien au sein des grosses ESN comme Capgemini ou ATOS, beaucoup de développeurs se tournent vers l'entrepreneuriat en pensant que ça donnera du sens à leur vie professionnelle.\n\nSauf que ce n’est pas toujours le cas: Non seulement créer une entreprise ne donne pas defacto du sens à sa vie professionnelle, mais en plus, à vouloir à tout prix donner du sens à ce qu’on fait, on peut en oublier de construire une entreprise rentable.\n\nAlors comment peut-on lancer une boîte pérenne tout en étant fier de ce qu’on fait, motivé à se lever tous les matins et convaincu d’avoir un impact positif sur nos communautés ?",
    "type": 1,
    "platform": {
      "spotify": "https://open.spotify.com/episode/3KEYvjEF04VPJINFYuxHC1",
      "applePodcast": "https://podcasts.apple.com/fr/podcast/lance-toi-et-code/id1535757097",
      "castbox": "https://castbox.fm/episode/08---Qu%C3%AAte-de-cash-ou-qu%C3%AAte-de-sens---id3433042-id350878217"
    },
    "embededUrl": "https://anchor.fm/lancetoietcode/embed/episodes/08---Qute-de-cash-ou-qute-de-sens-epqlq2",
    "guestList": [
      {
        "name": "Frédéric Simons",
        "linkList": [
          {
            "name": "12 MVP",
            "url": "https://12mvp.fr/home"
          }
        ]
      }
    ]
  },
  {
    "id": 9,
    "title": "#09 - Comment recruter les pépites qui vont vous faire décoller",
    "description": "Aujourd’hui dans lance toi et code on vous emmène de 0 à 200, et au delà.\n\nEn effet, quand on monte une entreprise, à moins d’être freelance ou ‘mature dev’ comme on l’a vu dans l’épisode 6, il vient un temps où il faut embaucher pour croître.\n\nTout faire tout seul nous limite à gérer une très petite entreprise, qui atteindra rapidement un plafond, donc si l’on ambitionne de créer quelque chose de plus grand que nous-même, il faut apprendre à trouver les bonnes personnes, les former, déléguer, et les gérer tout en gardant la valeurs et la culture qui font notre entreprise.\n\nCependant recruter ses premiers salariés est difficile et dangereux:\n\nQuand un bon recrutement peut faire décoller notre boîte, un mauvais peut très bien la couler ; surtout dans un système comme en France où il est très coûteux de rompre un contrat de travail.\n\nAussi, on peut avoir réussi à embaucher ses 10, 20, premiers salariés, mais pour ‘scale’ - ce terme anglais qui signifie “passer à l’échelle” - et passer de 20 à 150 salariés, on doit faire face à des challenges d’une toute autre envergure.\n\nAlors comment ont fait les entrepreneurs à succès pour scale et recruter, 1, puis 10 puis 200 employés ?",
    "type": 1,
    "platform": {
      "spotify": "https://open.spotify.com/episode/7wuIkpwn0NZWITR0mo8sfB",
      "applePodcast": "https://podcasts.apple.com/fr/podcast/lance-toi-et-code/id1535757097",
      "castbox": "https://castbox.fm/episode/09---Comment-recruter-les-p%C3%A9pites-qui-vont-vous-faire-d%C3%A9coller-id3433042-id353310565"
    },
    "embededUrl": "https://anchor.fm/lancetoietcode/embed/episodes/09---Comment-recruter-les-ppites-qui-vont-vous-faire-dcoller-eq5ba4",
    "guestList": [
      {
        "name": "Romain Parent",
        "linkList": [
          {
            "name": "Sublime",
            "url": "https://www.sublime.xyz/"
          }, {
            "name": "ShippingBo",
            "url": "https://shippingbo.com/"
          }
        ]
      }
    ]
  },
  {
    "id": 10,
    "title": "#10 - Vendre quand on a un Saas, vers son 1er client et au delà",
    "description": "Aujourd’hui dans Lance toi et code on parle de sujets qui fâchent.\n\nQuand on est développeur - et même quand on ne l’est pas - vendre est un tabou auquel on préfère ne pas penser.\n\nPourtant, on peut avoir créé le meilleur produit du monde, si on ne le vend pas, on a pas de business.\n\nSauf que vendre c’est dur. Alors comment faire ses premières ventes lorsque l’on monte sa boite, et comment faire grandir sa structure commerciale avec son entreprise ?",
    "type": 1,
    "platform": {
      "spotify": "https://open.spotify.com/episode/29qJRvJrnfqmPqtRAIaKJd",
      "applePodcast": "https://podcasts.apple.com/fr/podcast/lance-toi-et-code/id1535757097",
      "castbox": "https://castbox.fm/episode/10---Vendre-quand-on-a-un-Saas%2C-vers-son-1er-client-et-au-del%C3%A0-id3433042-id355598066"
    },
    "embededUrl": "https://anchor.fm/lancetoietcode/embed/episodes/10---Vendre-quand-on-a-un-Saas--vers-son-1er-client-et-au-del-eqf5sr",
    "guestList": [
      {
        "name": "Sunny Paris",
        "linkList": [
          {
            "name": "noCRM.io",
            "url": "https://youdontneedacrm.com/"
          }
        ]
      }
    ]
  },
  {
    "id": 11,
    "title": "🛠TB#01 - Comment valider son idée, quand tout le monde nous ment ?",
    "description": "Cet épisode s'appuie sur le livre The Mom Test (http://momtestbook.com/)\n\nPour ne pas passer 6 mois à développer un produit dont au final personne ne veut, il faut tout d’abord s’assurer d’aller à la rencontre de ses potentiels clients pour valider ou invalider son idée.\n\nMais comment parler à ses clients et savoir si son idée de business est bonne, quand tout le monde nous ment ?",
    "type": 2,
    "platform": {
      "spotify": "https://open.spotify.com/episode/5Fwjfe5pPnmtIqqchNtDUb",
      "applePodcast": "https://podcasts.apple.com/fr/podcast/lance-toi-et-code/id1535757097",
      "castbox": "https://castbox.fm/episode/%F0%9F%9B%A0TB01---Comment-valider-son-id%C3%A9e%2C-quand-tout-le-monde-nous-ment---id3433042-id360248655"
    },
    "embededUrl": "https://anchor.fm/lancetoietcode/embed/episodes/TB01---Comment-valider-son-ide--quand-tout-le-monde-nous-ment-era09r"
  },
  {
    "id": 12,
    "title": "#11 - Pour maitriser votre dette technique, apprenez à glander",
    "description": "Aujourd’hui dans Lance Toi Et Code on apprend à gérer la dette technique de notre start-up pour scale plus sereinement.\n\nDans cet épisode Martin Donadieu va nous apprendre:\n\nComment mettre en place des systèmes d’amélioration continue qui limitent la création de dette technique sans perdre en agilité\nComment mettre l’utilisateur au centre des préoccupations permet de mieux prioriser le paiement de la dette technique\nPourquoi s’assurer que les équipes techniques ont du temps pour glander est essentiel pour limiter sa dette technique\n\n\nLes livres : The Mom Test (http://momtestbook.com/)\n\nLes podcasts : Indie Makers (https://indiemakers.fr/) | Artisan Développeur (https://artisandeveloppeur.fr/",
    "type": 1,
    "platform": {
      "spotify": "https://open.spotify.com/episode/05qUj4eGj8dJWBGdFUDMnd",
      "applePodcast": "https://podcasts.apple.com/fr/podcast/lance-toi-et-code/id1535757097",
      "castbox": "https://castbox.fm/episode/11---Pour-maitriser-votre-dette-technique%2C-apprenez-%C3%A0-glander-id3433042-id362340250"
    },
    "embededUrl": "https://anchor.fm/lancetoietcode/embed/episodes/11---Pour-maitriser-votre-dette-technique--apprenez--glander-es18ad",
    "guestList": [
      {
        "name": "Martin Donadieu",
        "linkList": [
          {
            "name": "Indie Makers",
            "url": "https://indiemakers.fr/"
          }
        ]
      }
    ]
  },
  {
    "id": 13,
    "title": "📓JDB#01 - On bootstrap notre 1er Saas (et on raconte tout en podcast !)",
    "description": "Le problème quand on écoute les success story des entrepreneurs, c’est qu’ils nous racontent toujours l’histoire bien léchée à posteriori. Sauf qu’ils ne nous disent pas toutes les petites galères et les petits choix du quotidien, qui ont fait la différence.\n\nDans ce 1er épisode du Journal De Bord, on vous raconte comment on a trouvé notre idée de Sass, raté nos deux premiers lancements, et quand même réussi à valider notre idée !",
    "type": 2,
    "platform": {
      "spotify": "https://open.spotify.com/episode/7jTG82mLzCOa13Tim8xNrx",
      "applePodcast": "https://podcasts.apple.com/fr/podcast/lance-toi-et-code/id1535757097",
      "castbox": "https://castbox.fm/episode/%F0%9F%93%93JDB01---On-bootstrap-notre-1er-Saas-(et-on-raconte-tout-en-podcast-!)-id3433042-id364321999"
    },
    "embededUrl": "https://anchor.fm/lancetoietcode/embed/episodes/JDB01---On-bootstrap-notre-1er-Saas-et-on-raconte-tout-en-podcast-esm59t"
  },
  {
    "id": 14,
    "title": "#12 - Pourquoi les devs sont les meilleurs en marketing",
    "description": "Aujourd’hui dans Lance Toi Et Code on reçoit quelqu’un qui travail depuis des années à l'émancipation des développeurs entrepreneurs Trop souvent, on se retrouve avec des personnes sorties d'écoles de commerce qui cherchent un CTO (càd un développeur non payé) et qui nous disent “t’inquiète je m’occupe de la partie business” Alors comment faire pour monter sa boîte quand on est meilleur en programmation qu’en business plan ? Dans cet épisode vous allez apprendre : Comment entreprendre tout en restant salarié Comment gagner en leadership et en influence dans votre entreprise Comment faire levier sur vos compétences de développeur, pour faire passer vos équipes et votre entreprise au niveau supérieur en marketing",
    "type": 1,
    "platform": {
      "spotify": "https://open.spotify.com/episode/6BrSb4yO2qgSsqpNb6KPYu",
      "applePodcast": "https://podcasts.apple.com/fr/podcast/lance-toi-et-code/id1535757097",
      "castbox": "https://castbox.fm/episode/12---Pourquoi-les-devs-sont-les-meilleurs-en-marketing-id3433042-id366611305"
    },
    "embededUrl": "https://anchor.fm/lancetoietcode/embed/episodes/12---Pourquoi-les-devs-sont-les-meilleurs-en-marketing-et8ite",
    "guestList": [
      {
        "name": "Daniel Jarjoura",
        "linkList": [
          {
            "name": "TLT21",
            "url": "https://www.tlt21.com/"
          }
        ]
      }
    ]
  },
  {
    "id": 15,
    "title": "📓JDB#02 - On design notre feature centrale (avec du code jetable !)",
    "description": "Dans ce 2e épisode du journal de bord, on vous raconte comment on a trouvé nos 7 premiers bêta-testeurs et comment on a décidé quelles fonctionnalités tester en premier !",
    "type": 2,
    "platform": {
      "spotify": "https://open.spotify.com/episode/1OHTNt4sw7EVxouqcJLiDH",
      "applePodcast": "https://podcasts.apple.com/fr/podcast/lance-toi-et-code/id1535757097",
      "castbox": "https://castbox.fm/episode/%F0%9F%93%93JDB02---On-design-notre-feature-centrale-(avec-du-code-jetable-!)-id3433042-id368720114"
    },
    "embededUrl": "https://anchor.fm/lancetoietcode/embed/episodes/JDB02---On-design-notre-feature-centrale-avec-du-code-jetable-etr0hd"
  },
  {
    "id": 16,
    "title": "#13 - Pensez votre start-up comme le ferait un investisseur",
    "description": "Et si en tant qu’entrepreneur on voyait notre boîte comme un VC, pour définir si oui ou non on devrait y investir les 7 prochaines années de notre vie ?\n\nPour nous apprendre à penser comme un investisseur, on a l’honneur d’accueillir aujourd’hui Jean de la Rochebrochard, qui s’occupe du fonds d’investissement de Xavier Niel.\n\nJ2LR pour les intimes est une véritable rockstar de la scène start-up française.\n\nIl partage aujourd’hui avec nous les leçons qu’il a apprises en investissant dans 2 start-ups par semaines pendant presque 10 ans.\n\nDans cet épisode vous allez apprendre :\n\nPourquoi un bon entrepreneur est plein de paradoxes\nL’importance d’être obstiné\nQuels sont les trois profils essentiels à une équipe fondatrice",
    "type": 1,
    "platform": {
      "spotify": "https://open.spotify.com/episode/7zAIoA3zE1dTfQDCybSTCy",
      "applePodcast": "",
      "castbox": "https://castbox.fm/episode/13---Comment-savoir-si-l%E2%80%99on-devrait-miser-sur-nous---Avec-Jean-de-La-Rochebrochard-id3433042-id370675595"
    },
    "embededUrl": "https://anchor.fm/lancetoietcode/embed/episodes/13---Comment-savoir-si-lon-devrait-miser-sur-nous---Avec-Jean-de-La-Rochebrochard-eu9tus",
    "guestList": [
      {
        "name": "Jean de La Rochebrochard",
        "linkList": [
          {
            "name": "Kima Ventures",
            "url": "https://www.kimaventures.com/"
          }
        ]
      }
    ]
  },
  {
    "id": 17,
    "title": "📓JDB#03 - On n'écoute pas nos beta testeurs (et on a raison !)",
    "description": "Dans ce 3e épisode du journal de bord, on vous raconte comment on a testé notre prototype avec nos premier utilisateurs, pourquoi on a pas écouté leurs demandes de fonctionnalités, pourquoi on a eu raison, et ce qu’on a appris de tout ça.\nEt aujourd’hui : On fait tester notre prototype avec nos premiers utilisateurs",
    "type": 2,
    "platform": {
      "spotify": "https://open.spotify.com/episode/32LK7jkf1eURjDP2fpZld5",
      "applePodcast": "https://podcasts.apple.com/fr/podcast/lance-toi-et-code/id1535757097",
      "castbox": "https://castbox.fm/episode/%F0%9F%93%93JDB03---On-n'%C3%A9coute-pas-nos-beta-testeurs-(et-on-a-raison-!)-id3433042-id372774437"
    },
    "embededUrl": "https://anchor.fm/lancetoietcode/embed/episodes/JDB03---On-ncoute-pas-nos-beta-testeurs-et-on-a-raison-eup7kj"
  }, {
    "id": 18,
    "title": "#14 - Comment 'productiser' sa start-up de services",
    "description": "Monter et “scale” une entreprise basée sur un produit c’est déjà dur.\n\n    Alors créer et faire grandir une entreprise de service, à l’international, avec une mission forte et assumée d’aider les communautés dans lesquelles on opère, tout en dégageant du profit, ça paraît être mission impossible.\n    \n    Pour comprendre comment réaliser une telle vision, on reçoit Matina Razafimahefa, qui va nous expliquer comment elle a réussi à lier le business et le social avec son entreprise : Sayna.",
    "type": 1,
    "platform": {
      "spotify": "https://open.spotify.com/episode/7i0dCBvbYrSoHIzx47HR6K",
      "applePodcast": "https://podcasts.apple.com/fr/podcast/lance-toi-et-code/id1535757097",
      "castbox": "https://castbox.fm/episode/14---Comment-'productiser'-sa-start-up-de-services---Avec-Matina-Razafimahefa-id3433042-id374666795"
    },
    "embededUrl": "https://anchor.fm/lancetoietcode/embed/episodes/14---Comment-productiser-sa-start-up-de-services---Avec-Matina-Razafimahefa-ev7jpb",
    "guestList": [
      {
        "name": "Matina Razafimahefa",
        "linkList": [
          {
            "name": "Sayna",
            "url": "http://sayna.io/"
          }
        ]
      }
    ]
  }, {
    "id": 19,
    "title": "📓JDB#04 - On est beaucoup trop lents (mais du coup on accélère !)",
    "description": "Cette semaine dans le Journal De Bord de Lance Toi Et Code on fait tester la v2 de notre prototype sans avoir implémenté les demandes de nos bêta-testeurs",
    "type": 2,
    "platform": {
      "spotify": "https://open.spotify.com/episode/0SeryRuLEPeSgWDnbVEh0v",
      "applePodcast": "https://podcasts.apple.com/fr/podcast/lance-toi-et-code/id1535757097",
      "castbox": "https://castbox.fm/episode/%F0%9F%93%93JDB04---On-est-beaucoup-trop-lents-(mais-du-coup-on-acc%C3%A9l%C3%A8re-!)-id3433042-id376702171"
    },
    "embededUrl": "https://anchor.fm/lancetoietcode/embed/episodes/JDB04---On-est-beaucoup-trop-lents-mais-du-coup-on-acclre-evn81e"
  }, {
    "id": 20,
    "title": "#15 - Comment marketer son Saas sans cibler une niche",
    "description": "Aujourd’hui dans Lance toi et code, on accueille Alexandre Delivet, fondateur et dirigeant de Collect, une boite de SaaS qui aide des entreprises diverses à récupérer facilement et de manière centralisée les documents de leurs clients.\n\nMais Alex Delivet, c’est bien plus que ça. Véritable force tranquille de l’écosystème start-up et surtout du SaaS français, il est de ceux que tout le monde connaît mais dont peu de gens parlent.\n\nAlexandre est un sérial entrepreneur, qui, après de nombreuses expériences et faits d’armes dans les start-ups SaaS, a lancé le podcast “SaaS connections” pour aller à la rencontre de ceux qui font le SaaS francophone.\n\nDans cet épisode qu’on a adoré enregistrer, vous allez apprendre:\n\nPourquoi les SaaS vont (ou pas) conquérir le monde\nA réussir l’onboarding de votre produit\nQu’on peut aussi vendre des conférences et pas que des boîtes",
    "type": 1,
    "platform": {
      "spotify": "https://open.spotify.com/episode/5DSOx0zUgnolaGPAyoTc1o",
      "applePodcast": "https://podcasts.apple.com/fr/podcast/lance-toi-et-code/id1535757097",
      "castbox": "https://castbox.fm/episode/15---Comment-marketer-son-Saas-sans-cibler-une-niche---Avec-Alex-Delivet-id3433042-id378774760"
    },
    "embededUrl": "https://anchor.fm/lancetoietcode/embed/episodes/15---Comment-marketer-son-Saas-sans-cibler-une-niche---Avec-Alex-Delivet-e1061sb",
    "guestList": [
      {
        "name": "Alex Delivet",
        "linkList": [
          {
            "name": "Saas Connection",
            "url": "https://saas-connection.com/"
          }
        ]
      }
    ]
  }, {
    "id": 21,
    "title": "📓JDB#05 - On commence à dev (et on change de nom !)",
    "description": "Cette semaine on vous présente les choix qu'on a fait pour notre stack technique et pourquoi.",
    "type": 2,
    "platform": {
      "spotify": "https://open.spotify.com/episode/6IqTDs4DQSs6MnK7D6Mf19",
      "applePodcast": "https://podcasts.apple.com/fr/podcast/lance-toi-et-code/id1535757097",
      "castbox": "https://castbox.fm/episode/%F0%9F%93%93JDB05---On-commence-%C3%A0-dev-(et-on-change-de-nom-!)-id3433042-id381206523"
    },
    "embededUrl": "https://anchor.fm/lancetoietcode/embed/episodes/JDB05---On-commence--dev-et-on-change-de-nom-e10kn62",
  }, {
    "id": 22,
    "title": "#16 - Faire passer son Saas de 10 à 4.000 clients payants en 15 jours",
    "description": "Dans cet épisode Tom nous raconte l'histoire de PixelMe qu'il a commencé comme un side project.\n    Comment il a trouvé l'idée, comment il a trouvé ses premiers clients, et comment il est passé de 10 à 4.000 clients en 15 jours,\n    \n    \n    Jusqu'à la levée de fonds, et où il en est aujourd'hui, sans oublier l'épisode où il a reçu une lettre des avocats de Facebook !\n    \n    \n    Dance cet épisode vous allez apprendre :\n    \n    \n    - Comment se différencier sur un marché avec de TRES gros concurrents\n    \n    \n    - Comment déterminer son pricing : avec le retour d'expérience de Tom et de tout ce qu'il a essayé sur Pixel.Me\n    \n    \n    \n    \n    \n    Si vous êtes intéressé par l'offre de tom, envoyez un email à tom@pixelme.me",
    "type": 1,
    "platform": {
      "spotify": "https://open.spotify.com/episode/0bdeyUZTha1ywLzJU1FC0w",
      "applePodcast": "https://podcasts.apple.com/fr/podcast/lance-toi-et-code/id1535757097",
      "castbox": ""
    },
    "embededUrl": "https://anchor.fm/lancetoietcode/embed/episodes/16---Faire-passer-son-Saas-de-10--4-000-clients-payants-en-15-jours---Avec-Tom-Benattar-e112r9i",
    "guestList": [
      {
        "name": "Tom Benattar",
        "linkList": [
          {
            "name": "PixelMe",
            "url": "https://pixelme.me/"
          }
        ]
      }
    ]
  }, {
    "id": 23,
    "title": "📓JDB#06 - On est en plein doute (mais on prend un décision !)",
    "description": "Dans cet épisode #06 du Journal De Bord, on vous parle de nos doutes, de notre stratégie de distribution, et même de Jul !",
    "type": 2,
    "platform": {
      "spotify": "https://open.spotify.com/episode/4BRmpRPaz1FWXkZoAoRFpF",
      "applePodcast": "https://podcasts.apple.com/fr/podcast/lance-toi-et-code/id1535757097",
      "castbox": "https://castbox.fm/episode/%F0%9F%93%93JDB06---On-est-en-plein-doute-(mais-on-prend-une-d%C3%A9cision-!)-id3433042-id385331206"
    },
    "embededUrl": "https://anchor.fm/lancetoietcode/embed/episodes/JDB06---On-est-en-plein-doute-mais-on-prend-une-dcision-e11gof6"
  }, {
    "id": 24,
    "title": "#17 - Devenir Product-CEO et se rendre indispensable",
    "description": "Aujourd’hui ce qui est au centre d’une start-up c’est souvent le produit, que ce soit un objet physique, une app, un service ou un mélange des trois.\n\nMAIS l'idée de gérer un “Produit” c’est assez nouveau et dans une start-up tech ca implique de mêler la tech au business.\n\nALORS comment on communique entre équipes ? Comment on s’assure qu’on développe les bonnes fonctionnalités ?\n\nPour répondre à ces questions on accueille Hugo Michalski, CTPO de Side",
    "type": 1,
    "platform": {
      "spotify": "https://open.spotify.com/episode/6jeYc6AXhSdFwnQn5z7kNu",
      "applePodcast": "https://podcasts.apple.com/fr/podcast/lance-toi-et-code/id1535757097",
      "castbox": "https://castbox.fm/episode/17---Devenir-Product-CEO-et-se-rendre-indispensable---Avec-Hugo-Michalski-id3433042-id387357074"
    },
    "embededUrl": "https://anchor.fm/lancetoietcode/embed/episodes/17---Devenir-Product-CEO-et-se-rendre-indispensable---Avec-Hugo-Michalski-e11uc72",
    "guestList": [
      {
        "name": "Hugo Michalski",
        "linkList": [
          {
            "name": "Side",
            "url": "https://side.co/"
          }
        ]
      }
    ]
  }, {
    "id": 25,
    "title": "#18 - On a interviewé un agent secret qui pilote des robots qui automatisent la croissance",
    "description": "Dans cet épisode #18 de Lance Toi Et Code on reçoit Pierre Paris, Co-fondateur et CTO de MyNameIsBond\n\nMy Name Is Bond c'est L'agence (secrète) qui pilote des robots et automatise votre croissance\n\nDans cet épisode vous allez apprendre\n\n- Comment faire grossir votre communauté Instagram, en conservant l'engagement et sans risquer de vous faire bloquer\n\n- Comment automatiser vos process avec tous les outils dont il nous parle\n\n- Comment utiliser ces outils pour faire 80% du travail, en 20% du temps",
    "type": 1,
    "platform": {
      "spotify": "https://open.spotify.com/episode/2QM3k3eCLE1GomHSuBHsya",
      "applePodcast": "https://podcasts.apple.com/fr/podcast/lance-toi-et-code/id1535757097",
      "castbox": "https://castbox.fm/episode/18---On-a-interview%C3%A9-un-agent-secret-qui-pilote-des-robots-qui-automatisent-la-croissance---Avec-Pierre-Paris-id3433042-id391829039"
    },
    "embededUrl": "https://anchor.fm/lancetoietcode/embed/episodes/18---On-a-interview-un-agent-secret-qui-pilote-des-robots-qui-automatisent-la-croissance---Avec-Pierre-Paris-e12ouqj",
    "guestList": [
      {
        "name": "Pierre Paris",
        "linkList": [
          {
            "name": "MyNameIsBond",
            "url": "https://mynameisbond.co/"
          }
        ]
      }
    ]
  }, {
    "id": 26,
    "title": "📓JDB#07 - On a failli être ruiné (et on vous annonce une nouvelle !)",
    "description": "Dans ce nouvel épisode du Journal de Bord, on vous raconte comment on a failli être ruiné, et on vous annonce une grande nouvelle !",
    "type": 2,
    "platform": {
      "spotify": "https://open.spotify.com/episode/6uvv0MxzAo0aEJEfsyxyik",
      "applePodcast": "https://podcasts.apple.com/fr/podcast/lance-toi-et-code/id1535757097",
      "castbox": "https://castbox.fm/episode/%F0%9F%93%93JDB07---On-a-failli-%C3%AAtre-runi%C3%A9-(et-on-vous-annonce-une-nouvelle-!)-id3433042-id399250128"
    },
    "embededUrl": "https://anchor.fm/lancetoietcode/embed/episodes/JDB07---On-a-failli-tre-runi-et-on-vous-annonce-une-nouvelle-e13jhqd"
  }, {
    "id": 27,
    "title": "📓JDB#08 - Ce qu'il s'est vraiment passé (notre 1er client ?)",
    "description": "Que s'est-il vraiment passé depuis le dernier épisode du Journal De Bord en Juin ?\n\nDans cet épisode on vous raconte toutes nos avancées, si on a réussi à enfin avoir notre 1er client payant, ce qu'on a essayé, nos réussites, nos échecs et ce que vous pouvez en apprendre.",
    "type": 2,
    "platform": {
      "spotify": "https://open.spotify.com/episode/5BCulzsaDAezBAwshYWgZJ",
      "applePodcast": "https://podcasts.apple.com/fr/podcast/lance-toi-et-code/id1535757097",
      "castbox": "https://castbox.fm/channel/Lance-Toi-Et-Code-id3433042"
    },
    "embededUrl": "https://anchor.fm/lancetoietcode/embed/episodes/JDB08---Ce-quil-sest-vraiment-pass-notre-1er-client-e1c00bm"
  }, {
    "id": 28,
    "title": "#19 - Comment réussir son acquisition et améliorer son onboarding",
    "description": "Après avoir lancé notre 1er Saas et acquis nos premiers clients on voudrait accélérer notre croissance.\n\nAlors on a invité Selim de MarkCopy pour apprendre à réussir notre acquisition et améliorer notre onboarding.\n\nSelim a lancé MarkCopy avec Kilian il y a seulement quelques mois et ont déjà +4k de MRR\n\nVoici notre conversation avec Selim ",
    "type": 1,
    "platform": {
      "spotify": "https://open.spotify.com/show/3JtCeVHg8bIw64SMZChCs9",
      "applePodcast": "https://podcasts.apple.com/fr/podcast/lance-toi-et-code/id1535757097",
      "castbox": "https://castbox.fm/channel/Lance-Toi-Et-Code-id3433042"
    },
    "embededUrl": "https://anchor.fm/lancetoietcode/embed/episodes/19---Comment-russir-son-acquisition-et-amliorer-son-onboarding---avec-Selim-Chehimi-e1cf42n",
    "guestList": [
      {
        "name": "Selim Chehimi",
        "linkList": [
          {
            "name": "MarkCopy",
            "url": "https://www.markcopy.ai/"
          }
        ]
      }
    ]
  }, {
    "id": 29,
    "title": "📓JDB#09 - On a vraiment eu notre 1er client ! (Mais on est pas encore riche)",
    "description": "Enfin un client en chair et en os qui a rentré sa carte bleu sur notre site ?\n\nDans cet épisode on vous raconte toutes nos avancées, comment on a réussi à enfin avoir notre 1er client payant, ce qu'on a essayé, nos réussites, nos échecs et ce que vous pouvez en apprendre.",
    "type": 2,
    "platform": {
      "spotify": "https://open.spotify.com/show/3JtCeVHg8bIw64SMZChCs9",
      "applePodcast": "https://podcasts.apple.com/fr/podcast/lance-toi-et-code/id1535757097",
      "castbox": "https://castbox.fm/channel/Lance-Toi-Et-Code-id3433042"
    },
    "embededUrl": "https://anchor.fm/lancetoietcode/embed/episodes/JDB09---On-a-vraiment-eu-notre-1er-client---Mais-on-est-pas-encore-riche-e1d3725"
  }, {
    "id": 30,
    "title": "📓JDB#10 - On devient rentable (Avec seulement 3 clients)",
    "description": "On arrive à gagner de l'argent avec seulement 3 clients ?\n\nLa beauté du système des Saas ! Dans cet épisode on vous raconte toutes nos avancées, comment on a réussi à avoir notre 2 clients suivant, ce qu'on a essayé, nos réussites, nos échecs et ce que vous pouvez en apprendre.",
    "type": 2,
    "platform": {
      "spotify": "https://open.spotify.com/show/3JtCeVHg8bIw64SMZChCs9",
      "applePodcast": "https://podcasts.apple.com/fr/podcast/lance-toi-et-code/id1535757097",
      "castbox": "https://castbox.fm/channel/Lance-Toi-Et-Code-id3433042"
    },
    "embededUrl": "https://anchor.fm/lancetoietcode/embed/episodes/JDB10---On-devient-rentable-Avec-seulement-3-clients-e1do1l6"
  }, {
    "id": 31,
    "title": "📓JDB#11 - On perd notre 1er client (Mais on rebondit !)",
    "description": "On a reçu un mail terrible... Notre 1ere cliente qui veut arrêter son abonnement !\n\nMais on ne se laisse pas abattre, ce sont les hauts et les bas de l'aventure.\n\nAlors on s'est retroussé les manches et on a rebondit !\n\nOn vous raconte tout ça dans cet épisode numéro 11 du journal de bord.",
    "type": 2,
    "platform": {
      "spotify": "https://open.spotify.com/show/3JtCeVHg8bIw64SMZChCs9",
      "applePodcast": "https://podcasts.apple.com/fr/podcast/lance-toi-et-code/id1535757097",
      "castbox": "https://castbox.fm/channel/Lance-Toi-Et-Code-id3433042"
    },
    "embededUrl": "https://anchor.fm/lancetoietcode/embed/episodes/JDB11---On-perd-notre-1er-client-Mais-on-rebondit-e1ecv28"
  }, {
    "id": 32,
    "title": "#20 - Comment faire décoller son business grâce à Youtube",
    "description": "Thibault Houdon, 40k abonnés sur YouTube sous le nom de DocString, vient nous expliquer comment il a amené sa chaîne jusqu'à presque 40k abonnés et en a fait son 1er canal d'acquisition pour son business de formation.",
    "type": 1,
    "platform": {
      "spotify": "https://open.spotify.com/show/3JtCeVHg8bIw64SMZChCs9",
      "applePodcast": "https://podcasts.apple.com/fr/podcast/lance-toi-et-code/id1535757097",
      "castbox": "https://castbox.fm/channel/Lance-Toi-Et-Code-id3433042"
    },
    "embededUrl": "https://anchor.fm/lancetoietcode/embed/episodes/20---Comment-faire-dcoller-son-business-grce--Youtube---Avec-Thibault-Houdon-e1f27ao",
    "guestList": [
      {
        "name": "Thibault Houdon",
        "linkList": [
          {
            "name": "DocString",
            "url": "https://www.docstring.fr/"
          }
        ]
      }
    ]
  }, {
    "id": 33,
    "title": "📓JDB#12 - On lance 12 startups en 12 mois (1 client en 30 jours ?)",
    "description": "On a décidé de s'inspirer des meilleurs... Alors on va sortir plus de produits, plus rapidement et apprendre plus vite !\n\nPour ça : 1 produit et 30 jours pour faire des ventes, si on a pas de clients après 30 jours, on jette et on recommence.\n\nOn vous raconte comment on a fait pour lancer notre 1er produit en plus de notre Saas actuel et tout ce qu'on a appris.",
    "type": 2,
    "platform": {
      "spotify": "https://open.spotify.com/show/3JtCeVHg8bIw64SMZChCs9",
      "applePodcast": "https://podcasts.apple.com/fr/podcast/lance-toi-et-code/id1535757097",
      "castbox": "https://castbox.fm/channel/Lance-Toi-Et-Code-id3433042"
    },
    "embededUrl": "https://anchor.fm/lancetoietcode/embed/episodes/JDB12---On-lance-12-startups-en-12-mois-1-client-en-30-jours-e1footc"
  }, {
    "id": 34,
    "title": "#21 - Arriver 1er sur Product Hunt avec des cold-emails (avec Arnaud Belinga)",
    "description": "Arnaud Belinga, avec son co-fondateur, a créé BreakCold : la meilleure solution pour envoyer des cold emails personnalisés.\n\nC'est grâce à ce Saas qu'il est arrivé 1er sur Product Hunt lors de son lancement.\n\nIl nous explique dans cet épisode comment il a trouvé l'idée, créé le Saas, et fini 1er sur Product Hunt.",
    "type": 1,
    "platform": {
      "spotify": "https://open.spotify.com/show/3JtCeVHg8bIw64SMZChCs9",
      "applePodcast": "https://podcasts.apple.com/fr/podcast/lance-toi-et-code/id1535757097",
      "castbox": "https://castbox.fm/channel/Lance-Toi-Et-Code-id3433042"
    },
    "embededUrl": "https://anchor.fm/lancetoietcode/embed/episodes/21---Arriver-1er-sur-Product-Hunt-avec-des-cold-emails-e1gd17l",
    "guestList": [
      {
        "name": "Arnaud Belinga",
        "linkList": [
          {
            "name": "BreakCold",
            "url": "https://www.breakcold.com/"
          }
        ]
      }
    ]
  }, {
    "id": 35,
    "title": "📓JDB#13 - On a notre 1er client en 30 jours (Oui oui, vraiment !)",
    "description": "Lorsque l'on a lancé notre 1er Saas Lumina, on a mis plus de 6 mois entre l'idée et le 1er paiement stripe.\n\nC'est normal, c'était notre 1er.\n\nPour ce 2e on l'a fait en 30 jours !\n\nOn vous raconte comment on a fait, ce qu'on en a appris et comment vous pouvez faire pareil.",
    "type": 2,
    "platform": {
      "spotify": "https://open.spotify.com/show/3JtCeVHg8bIw64SMZChCs9",
      "applePodcast": "https://podcasts.apple.com/fr/podcast/lance-toi-et-code/id1535757097",
      "castbox": "https://castbox.fm/channel/Lance-Toi-Et-Code-id3433042"
    },
    "embededUrl": "https://anchor.fm/lancetoietcode/embed/episodes/JDB13---On-a-notre-1er-client-en-30-jours-Oui-oui--vraiment-e1hbmiq"
  }, {
    "id": 36,
    "title": "📓JDB#14 - On a failli tout jeter à la poubelle (Un dimanche soir compliqué !)",
    "description": "On s'est fixé jusqu'au dimanche soir pour réussir à fix un bug.\n\nSi on n'y arrive pas, c'est que c'est trop compliqué et autant ne pas se lancer dans ce projet.\n\nA 4h près on aurait pu tout jeter à la poubelle, et finalement ...\n\nOn vous raconte ce qui s'est passé dans ce 14e épisode du Journal de Bord.",
    "type": 2,
    "platform": {
      "spotify": "https://open.spotify.com/show/3JtCeVHg8bIw64SMZChCs9",
      "applePodcast": "https://podcasts.apple.com/fr/podcast/lance-toi-et-code/id1535757097",
      "castbox": "https://castbox.fm/channel/Lance-Toi-Et-Code-id3433042"
    },
    "embededUrl": "https://anchor.fm/lancetoietcode/embed/episodes/JDB14---On-a-failli-tout-jeter--la-poubelle-Un-dimanche-soir-compliqu-e1ia5g8"
  }, {
    "id": 0,
    "title": "",
    "description": "",
    "type": 0,
    "platform": {
      "spotify": "",
      "applePodcast": "",
      "castbox": ""
    },
    "embededUrl": "",
    "guestList": [
      {
        "name": "",
        "linkList": [
          {
            "name": "",
            "url": ""
          }
        ]
      }
    ]
  }
];

export default episodes;
