import React from 'react';
import Modal from '../Modal';
import Header from './Header';
import Body from './Body';

const Episode = () => {
  return (
    <div>
      <Modal />
      <Header />
      <Body />
    </div>
  );
};

export default Episode;
