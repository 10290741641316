/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import {
  Container,
  BackgroundWrapper,
  ListWrapper,
  Episode,
  EpisodeContent,
  Spotify,
  SpotifyMobile,
  ExtraText
} from './List.module.css';

function List() {
  return (
    <div className={Container}>
      <div className={BackgroundWrapper}></div>
      <div className={ListWrapper}>
        <h2>Les épisodes</h2>
        <h3>Saison 2</h3>
        <div className={Episode}>
          <img src="/images/JDB-14.png" alt="Lance Toi Et Code Journal De Bord 14" />
          <div className={EpisodeContent} onClick={() => window.location.href = '/ep/36'}>
            <span>📓JDB#14 - On a failli tout jeter à la poubelle (Un dimanche soir compliqué !)</span>
            <img className={SpotifyMobile} src="/images/spotify.svg" alt="Listen on spotify" />
            <br />
            <img className={Spotify} src="/images/spotify.svg" alt="Listen on spotify" />
          </div>
        </div>
        <div className={Episode}>
          <img src="/images/JDB-13.png" alt="Lance Toi Et Code Journal De Bord 13" />
          <div className={EpisodeContent} onClick={() => window.location.href = '/ep/35'}>
            <span>📓JDB#13 - On a notre 1er client en 30 jours (Oui oui, vraiment !)</span>
            <img className={SpotifyMobile} src="/images/spotify.svg" alt="Listen on spotify" />
            <br />
            <img className={Spotify} src="/images/spotify.svg" alt="Listen on spotify" />
          </div>
        </div>
        <div className={Episode}>
          <img src="/images/EP-21.png" alt="Lance Toi Et Code Breakcold" />
          <div className={EpisodeContent} onClick={() => window.location.href = '/ep/34'}>
            <span>#21 - Arriver 1er sur Product Hunt avec des cold-emails</span>
            <img className={SpotifyMobile} src="/images/spotify.svg" alt="Listen on spotify" />
            <br />
            <img className={Spotify} src="/images/spotify.svg" alt="Listen on spotify" />
          </div>
        </div>
        <div className={Episode}>
          <img src="/images/JDB-12.png" alt="Lance Toi Et Code Journal De Bord 12" />
          <div className={EpisodeContent} onClick={() => window.location.href = '/ep/33'}>
            <span>📓JDB#12 - On lance 12 startups en 12 mois (1 client en 30 jours ?)</span>
            <img className={SpotifyMobile} src="/images/spotify.svg" alt="Listen on spotify" />
            <br />
            <img className={Spotify} src="/images/spotify.svg" alt="Listen on spotify" />
          </div>
        </div>
        <div className={Episode}>
          <img src="/images/EP-20.png" alt="Lance Toi Et Code DocString" />
          <div className={EpisodeContent} onClick={() => window.location.href = '/ep/32'}>
            <span>#20 - Comment faire décoller son business grâce à Youtube</span>
            <img className={SpotifyMobile} src="/images/spotify.svg" alt="Listen on spotify" />
            <br />
            <img className={Spotify} src="/images/spotify.svg" alt="Listen on spotify" />
          </div>
        </div>
        <div className={Episode}>
          <img src="/images/JDB-11.png" alt="Lance Toi Et Code Journal De Bord 11" />
          <div className={EpisodeContent} onClick={() => window.location.href = '/ep/31'}>
            <span>📓JDB#11 - On perd notre 1er client (Mais on rebondit !)</span>
            <img className={SpotifyMobile} src="/images/spotify.svg" alt="Listen on spotify" />
            <br />
            <img className={Spotify} src="/images/spotify.svg" alt="Listen on spotify" />
          </div>
        </div>
        <div className={Episode}>
          <img src="/images/JDB-10.png" alt="Lance Toi Et Code Journal De Bord 10" />
          <div className={EpisodeContent} onClick={() => window.location.href = '/ep/30'}>
            <span>📓JDB#10 - On devient rentable (Avec seulement 3 clients)</span>
            <img className={SpotifyMobile} src="/images/spotify.svg" alt="Listen on spotify" />
            <br />
            <img className={Spotify} src="/images/spotify.svg" alt="Listen on spotify" />
          </div>
        </div>
        <div className={Episode}>
          <img src="/images/JDB-09.png" alt="Lance Toi Et Code Journal De Bord 09" />
          <div className={EpisodeContent} onClick={() => window.location.href = '/ep/29'}>
            <span>📓JDB#09 - On a vraiment eu notre 1er client ! (Mais on est pas encore riche)</span>
            <img className={SpotifyMobile} src="/images/spotify.svg" alt="Listen on spotify" />
            <br />
            <img className={Spotify} src="/images/spotify.svg" alt="Listen on spotify" />
          </div>
        </div>
        <div className={Episode}>
          <img src="/images/EP-19.png" alt="Lance Toi Et Code Selim Chehimi" />
          <div className={EpisodeContent} onClick={() => window.location.href = '/ep/28'}>
            <span>#19 - Comment réussir son acquisition et améliorer son onboarding</span>
            <img className={SpotifyMobile} src="/images/spotify.svg" alt="Listen on spotify" />
            <br />
            <div className={ExtraText}>Avec <b>Selim Chehimi</b> de <a href="https://www.markcopy.ai/" target="_blank" rel="noopener"><b>MarkCopy</b></a></div>
            <img className={Spotify} src="/images/spotify.svg" alt="Listen on spotify" />
          </div>
        </div>
        <div className={Episode}>
          <img src="/images/JDB-08.png" alt="Lance Toi Et Code Journal De Bord" />
          <div className={EpisodeContent} onClick={() => window.location.href = '/ep/27'}>
            <span>📓JDB#08 - Ce qu'il s'est vraiment passé (notre 1er client ?)</span>
            <img className={SpotifyMobile} src="/images/spotify.svg" alt="Listen on spotify" />
            <br />
            <img className={Spotify} src="/images/spotify.svg" alt="Listen on spotify" />
          </div>
        </div>
        <h3>Saison 1</h3>
        <div className={Episode}>
          <img src="/images/lancetoietcode-logo.png" alt="Lance Toi Et Code logo" />
          <div className={EpisodeContent} onClick={() => window.location.href = '/ep/26'}>
            <span>📓JDB#07 - On a failli être ruiné (et on vous annonce une nouvelle !)</span>
            <img className={SpotifyMobile} src="/images/spotify.svg" alt="Listen on spotify" />
            <br />
            <img className={Spotify} src="/images/spotify.svg" alt="Listen on spotify" />
          </div>
        </div>
        <div className={Episode}>
          <img src="/images/lancetoietcode-logo.png" alt="Lance Toi Et Code logo" />
          <div className={EpisodeContent} onClick={() => window.location.href = '/ep/25'}>
            <span>#18 - On a interviewé un agent secret qui pilote des robots qui automatisent la croissance</span>
            <img className={SpotifyMobile} src="/images/spotify.svg" alt="Listen on spotify" />
            <br />
            <div className={ExtraText}>Avec <b>Pierre Paris</b> de <a href="https://www.mynameisbond.co/" target="_blank" rel="noopener"><b>MyNameIsBond</b></a></div>
            <img className={Spotify} src="/images/spotify.svg" alt="Listen on spotify" />
          </div>
        </div>
        <div className={Episode}>
          <img src="/images/lancetoietcode-logo.png" alt="Lance Toi Et Code logo" />
          <div className={EpisodeContent} onClick={() => window.location.href = '/ep/24'}>
            <span>#17 - Devenir Product-CEO et se rendre indispensable</span>
            <img className={SpotifyMobile} src="/images/spotify.svg" alt="Listen on spotify" />
            <br />
            <div className={ExtraText}>Avec <b>Hugo Michalski</b> de <a href="https://www.side.co/" target="_blank" rel="noopener"><b>Side</b></a></div>
            <img className={Spotify} src="/images/spotify.svg" alt="Listen on spotify" />
          </div>
        </div>
        <div className={Episode}>
          <img src="/images/lancetoietcode-logo.png" alt="Lance Toi Et Code logo" />
          <div className={EpisodeContent} onClick={() => window.location.href = '/ep/23'}>
            <span>📓JDB#06 - On est en plein doute (mais on prend une décision !)</span>
            <img className={SpotifyMobile} src="/images/spotify.svg" alt="Listen on spotify" />
            <br />
            <img className={Spotify} src="/images/spotify.svg" alt="Listen on spotify" />
          </div>
        </div>
        <div className={Episode}>
          <img src="/images/lancetoietcode-logo.png" alt="Lance Toi Et Code logo" />
          <div className={EpisodeContent} onClick={() => window.location.href = '/ep/22'}>
            <span>#16 - Faire passer son Saas de 10 à 4.000 clients payants en 15 jours</span>
            <img className={SpotifyMobile} src="/images/spotify.svg" alt="Listen on spotify" />
            <br />
            <div className={ExtraText}>Avec <b>Tom Benattar</b> de <a href="https://pixelme.me/" target="_blank" rel="noopener"><b>PixelMe</b></a></div>
            <img className={Spotify} src="/images/spotify.svg" alt="Listen on spotify" />
          </div>
        </div>
        <div className={Episode}>
          <img src="/images/lancetoietcode-logo.png" alt="Lance Toi Et Code logo" />
          <div className={EpisodeContent} onClick={() => window.location.href = '/ep/21'}>
            <span>📓JDB#05 - On commence à dev (et on change de nom !)</span>
            <img className={SpotifyMobile} src="/images/spotify.svg" alt="Listen on spotify" />
            <br />
            <img className={Spotify} src="/images/spotify.svg" alt="Listen on spotify" />
          </div>
        </div>
        <div className={Episode}>
          <img src="/images/lancetoietcode-logo.png" alt="Lance Toi Et Code logo" />
          <div className={EpisodeContent} onClick={() => window.location.href = '/ep/20'}>
            <span>#15 - Comment marketer son Saas sans cibler une niche</span>
            <img className={SpotifyMobile} src="/images/spotify.svg" alt="Listen on spotify" />
            <br />
            <div className={ExtraText}>Avec <b>Alex Delivet</b> de <a href="https://saas-connection.com/" target="_blank" rel="noopener"><b>Saas Connection</b></a></div>
            <img className={Spotify} src="/images/spotify.svg" alt="Listen on spotify" />
          </div>
        </div>
        <div className={Episode}>
          <img src="/images/lancetoietcode-logo.png" alt="Lance Toi Et Code logo" />
          <div className={EpisodeContent} onClick={() => window.location.href = '/ep/19'}>
            <span>📓JDB#04 - On est beaucoup trop lents (mais du coup on accélère !)</span>
            <img className={SpotifyMobile} src="/images/spotify.svg" alt="Listen on spotify" />
            <br />
            <img className={Spotify} src="/images/spotify.svg" alt="Listen on spotify" />
          </div>
        </div>
        <div className={Episode}>
          <img src="/images/lancetoietcode-logo.png" alt="Lance Toi Et Code logo" />
          <div className={EpisodeContent} onClick={() => window.location.href = '/ep/18'}>
            <span>#14 - Comment 'productiser' sa start-up de services</span>
            <img className={SpotifyMobile} src="/images/spotify.svg" alt="Listen on spotify" />
            <br />
            <div className={ExtraText}>Avec <b>Matina Razafimahefa</b> de <a href="http://sayna.io/" target="_blank" rel="noopener"><b>Sayna</b></a></div>
            <img className={Spotify} src="/images/spotify.svg" alt="Listen on spotify" />
          </div>
        </div>
        <div className={Episode}>
          <img src="/images/lancetoietcode-logo.png" alt="Lance Toi Et Code logo" />
          <div className={EpisodeContent} onClick={() => window.location.href = '/ep/17'}>
            <span>📓JDB#03 - On n'écoute pas nos beta testeurs (et on a raison !)</span>
            <img className={SpotifyMobile} src="/images/spotify.svg" alt="Listen on spotify" />
            <br />
            <img className={Spotify} src="/images/spotify.svg" alt="Listen on spotify" />
          </div>
        </div>
        <div className={Episode}>
          <img src="/images/lancetoietcode-logo.png" alt="Lance Toi Et Code logo" />
          <div className={EpisodeContent} onClick={() => window.location.href = '/ep/16'}>
            <span>#13 - Pensez votre start-up comme le ferait un investisseur</span>
            <img className={SpotifyMobile} src="/images/spotify.svg" alt="Listen on spotify" />
            <br />
            <div className={ExtraText}>Avec <b>Jean de La Rochebrochard</b> de <a href="https://www.kimaventures.com/" target="_blank" rel="noopener"><b>Kima Ventures</b></a></div>
            <img className={Spotify} src="/images/spotify.svg" alt="Listen on spotify" />
          </div>
        </div>
        <div className={Episode}>
          <img src="/images/lancetoietcode-logo.png" alt="Lance Toi Et Code logo" />
          <div className={EpisodeContent} onClick={() => window.location.href = '/ep/15'}>
            <span>📓JDB#02 - On design notre feature centrale (avec du code jetable !)</span>
            <img className={SpotifyMobile} src="/images/spotify.svg" alt="Listen on spotify" />
            <br />
            <img className={Spotify} src="/images/spotify.svg" alt="Listen on spotify" />
          </div>
        </div>
        <div className={Episode}>
          <img src="/images/lancetoietcode-logo.png" alt="Lance Toi Et Code logo" />
          <div className={EpisodeContent} onClick={() => window.location.href = '/ep/14'}>
            <span>#12 - Pourquoi les devs sont les meilleurs en marketing</span>
            <img className={SpotifyMobile} src="/images/spotify.svg" alt="Listen on spotify" />
            <br />
            <div className={ExtraText}>Avec <b>Daniel Jarjoura</b> de <a href="https://www.tlt21.com/" target="_blank" rel="noopener"><b>TLT21</b></a></div>
            <img className={Spotify} src="/images/spotify.svg" alt="Listen on spotify" />
          </div>
        </div>
        <div className={Episode}>
          <img src="/images/lancetoietcode-logo.png" alt="Lance Toi Et Code logo" />
          <div className={EpisodeContent} onClick={() => window.location.href = '/ep/13'}>
            <span>📓 JDB#01 - On bootstrap notre 1er Saas (et on raconte tout en podcast !)</span>
            <img className={SpotifyMobile} src="/images/spotify.svg" alt="Listen on spotify" />
            <br />
            <img className={Spotify} src="/images/spotify.svg" alt="Listen on spotify" />
          </div>
        </div>
        <div className={Episode}>
          <img src="/images/lancetoietcode-logo.png" alt="Lance Toi Et Code logo" />
          <div className={EpisodeContent} onClick={() => window.location.href = '/ep/12'}>
            <span>#11 - Pour maitriser votre dette technique, apprenez à glander</span>
            <img className={SpotifyMobile} src="/images/spotify.svg" alt="Listen on spotify" />
            <br />
            <div className={ExtraText}>Avec <b>Martin Donadieu</b> de <a href="https://indiemakers.fr/" target="_blank" rel="noopener"><b>Indie Makers</b></a></div>
            <img className={Spotify} src="/images/spotify.svg" alt="Listen on spotify" />
          </div>
        </div>
        <div className={Episode}>
          <img src="/images/lancetoietcode-logo.png" alt="Lance Toi Et Code logo" />
          <div className={EpisodeContent} onClick={() => window.location.href = '/ep/11'}>
            <span>🛠 TB#01 - Valider son idée, quand tout le monde nous ment</span>
            <img className={SpotifyMobile} src="/images/spotify.svg" alt="Listen on spotify" />
            <br />
            <img className={Spotify} src="/images/spotify.svg" alt="Listen on spotify" />
          </div>
        </div>
        <div className={Episode}>
          <img src="/images/lancetoietcode-logo.png" alt="Lance Toi Et Code logo" />
          <div className={EpisodeContent} onClick={() => window.location.href = '/ep/10'}>
            <span>#10 - Vendre quand on a un Saas, vers son 1er client et au delà</span>
            <img className={SpotifyMobile} src="/images/spotify.svg" alt="Listen on spotify" />
            <br />
            <div className={ExtraText}>Avec <b>Sunny Paris</b> de <a href="https://youdontneedacrm.com/" target="_blank" rel="noopener"><b>noCRM.io</b></a></div>
            <img className={Spotify} src="/images/spotify.svg" alt="Listen on spotify" />
          </div>
        </div>
        <div className={Episode}>
          <img src="/images/lancetoietcode-logo.png" alt="Lance Toi Et Code logo" />
          <div className={EpisodeContent} onClick={() => window.location.href = '/ep/9'}>
            <span>#09 - Comment recruter les pépites qui vont vous faire décoller</span>
            <img className={SpotifyMobile} src="/images/spotify.svg" alt="Listen on spotify" />
            <br />
            <div className={ExtraText}>Avec <b>Romain Parent</b> de <a href="https://www.sublime.xyz/" target="_blank" rel="noopener"><b>Sublime</b></a> et <a href="https://shippingbo.com/" target="_blank" rel="noopener"><b>ShippingBo</b></a></div>
            <img className={Spotify} src="/images/spotify.svg" alt="Listen on spotify" />
          </div>
        </div>
        <div className={Episode}>
          <img src="/images/lancetoietcode-logo.png" alt="Lance Toi Et Code logo" />
          <div className={EpisodeContent} onClick={() => window.location.href = '/ep/8'}>
            <span>#08 - Quête de cash ou quête de sens ?</span>
            <img className={SpotifyMobile} src="/images/spotify.svg" alt="Listen on spotify" />
            <br />
            <div className={ExtraText}>Avec <b>Frédéric Simons</b> de <a href="https://12mvp.fr/home" target="_blank" rel="noopener"><b>12 MVP</b></a></div>
            <img className={Spotify} src="/images/spotify.svg" alt="Listen on spotify" />
          </div>
        </div>
        <div className={Episode}>
          <img src="/images/lancetoietcode-logo.png" alt="Lance Toi Et Code logo" />
          <div className={EpisodeContent} onClick={() => window.location.href = '/ep/7'}>
            <span>#07 - Comment dire à ses utilisateurs gratuits, qu'il vont devoir payer ?</span>
            <img className={SpotifyMobile} src="/images/spotify.svg" alt="Listen on spotify" />
            <br />
            <div className={ExtraText}>Avec <b>Remi Duvoux</b> de <a href="https://www.okarito.io/" target="_blank" rel="noopener"><b>Okarito</b></a></div>
            <img className={Spotify} src="/images/spotify.svg" alt="Listen on spotify" />
          </div>
        </div>
        <div className={Episode}>
          <img src="/images/lancetoietcode-logo.png" alt="Lance Toi Et Code logo" />
          <div className={EpisodeContent} onClick={() => window.location.href = '/ep/6'}>
            <span>#06 - Monter sa boite sans bureaux, sans employés, et sans VC</span>
            <img className={SpotifyMobile} src="/images/spotify.svg" alt="Listen on spotify" />
            <br />
            <div className={ExtraText}>Avec <b>Anthony Heukmes</b> de <a href="https://themature.dev/" target="_blank" rel="noopener"><b>The Mature Developer</b></a></div>
            <img className={Spotify} src="/images/spotify.svg" alt="Listen on spotify" />
          </div>
        </div>
        <div className={Episode}>
          <img src="/images/lancetoietcode-logo.png" alt="Lance Toi Et Code logo" />
          <div className={EpisodeContent} onClick={() => window.location.href = '/ep/5'}>
            <span>#05 - VC et Champagne: Comment et pourquoi lever des fonds</span>
            <img className={SpotifyMobile} src="/images/spotify.svg" alt="Listen on spotify" />
            <br />
            <div className={ExtraText}>
              Avec <b>Clément Renault</b> de <a href="https://www.louveinvest.com/" target="_blank" rel="noopener"><b>Louve Invest</b></a>, 
              <b> Victoire Laurenty</b> de <a href="https://www.kerala.vc/" target="_blank" rel="noopener"><b>Kerala Ventures</b></a> et 
              <b> Romain Salzman</b> de <a href="http://flashbreak.com/" target="_blank" rel="noopener"><b>Flashbreak</b></a>
            </div>
            <img className={Spotify} src="/images/spotify.svg" alt="Listen on spotify" />
          </div>
        </div>
        <div className={Episode}>
          <img src="/images/lancetoietcode-logo.png" alt="Lance Toi Et Code logo" />
          <div className={EpisodeContent} onClick={() => window.location.href = '/ep/4'}>
            <span>#04 - Comment naviguer dans la jungle administrative ?</span>
            <img className={SpotifyMobile} src="/images/spotify.svg" alt="Listen on spotify" />
            <br />
            <div className={ExtraText}>Avec <b>Estelle Zeliszewski</b> de <a href="https://www.shine.fr/" target="_blank" rel="noopener"><b>Shine</b></a></div>
            <img className={Spotify} src="/images/spotify.svg" alt="Listen on spotify" />
          </div>
        </div>
        <div className={Episode}>
          <img src="/images/lancetoietcode-logo.png" alt="Lance Toi Et Code logo" />
          <div className={EpisodeContent} onClick={() => window.location.href = '/ep/3'}>
            <span>#03 - Rester motiver, même quand on perd 1 million d'euros</span>
            <img className={SpotifyMobile} src="/images/spotify.svg" alt="Listen on spotify" />
            <br />
            <div className={ExtraText}>Avec <b>Guillaume Faia</b> de <a href="https://honikougames.com/" target="_blank" rel="noopener"><b>Honikou Games</b></a></div>
            <img className={Spotify} src="/images/spotify.svg" alt="Listen on spotify" />
          </div>
        </div>
        <div className={Episode}>
          <img src="/images/lancetoietcode-logo.png" alt="Lance Toi Et Code logo" />
          <div className={EpisodeContent} onClick={() => window.location.href = '/ep/2'}>
            <span>#02 - Dites "No" au code !</span>
            <img className={SpotifyMobile} src="/images/spotify.svg" alt="Listen on spotify" />
            <br />
            <div className={ExtraText}>Avec <b>Thibaut Marty</b> de <a href="https://ottho.fr/" target="_blank" rel="noopener"><b>Ottho</b></a></div>
            <img className={Spotify} src="/images/spotify.svg" alt="Listen on spotify" />
          </div>
        </div>
        <div className={Episode}>
          <img src="/images/lancetoietcode-logo.png" alt="Lance Toi Et Code logo" />
          <div className={EpisodeContent} onClick={() => window.location.href = '/ep/1'}>
            <span>#01 - L’idée à 1 milliard, ou un milliard d’idées ?</span>
            <img className={SpotifyMobile} src="/images/spotify.svg" alt="Listen on spotify" />
            <br />
            <div className={ExtraText}>Avec <b>Mathieu Nohet</b> de <a href="https://www.manty.eu/" target="_blank" rel="noopener"><b>Manty</b></a></div>
            <img className={Spotify} src="/images/spotify.svg" alt="Listen on spotify" />
          </div>
        </div>
        <div className={Episode}>
          <img src="/images/lancetoietcode-logo.png" alt="Lance Toi Et Code logo" />
          <div className={EpisodeContent} onClick={() => window.location.href = '/ep/0'}>
            <span>Episode 00 - Présentation du podcast</span>
            <img className={SpotifyMobile} src="/images/spotify.svg" alt="Listen on spotify" />
            <img className={Spotify} src="/images/spotify.svg" alt="Listen on spotify" />
          </div>
        </div>
        <p style={{ color: '#EBEBEC' }}>With the help of Rio</p>
      </div>
    </div>
  );
};

export default List;