import React from 'react';
import {
  Container,
  BackgroundWrapper,
  HeroWrapper,
  LeftPart,
  RightPart,
  DescriptionText,
  CallToAction,
  InputWrapper,
  Input,
  Button
} from './Hero.module.css';

function Hero() {
  return (
    <div className={Container}>
      <div className={BackgroundWrapper}></div>
      <div className={HeroWrapper}>
        <div className={LeftPart}>
          <img src="/images/lancetoietcode-logo.png" alt="Lance Toi Et Code logo" />
        </div>
        <div className={RightPart}>
          <div className={DescriptionText}>
            <h1>Lance Toi Et Code</h1>
            <p>De Thomas Ferry et Nicolas Laurent</p>
            <p>
              Le podcast des développeurs qui entreprennent.<br />
              Profitez des retours d’expérience d’entrepreneurs qui ont surmonté les épreuves de la 
              création de leur start-up et qui partagent avec vous tous leurs conseils !
            </p>
          </div>
          <br />
          <form className={CallToAction} action="https://lancetoietcode.us2.list-manage.com/subscribe/post?u=df4d0a3d0404e2aa527fa4e62&amp;id=378b1254bc" method="post" target="_blank" noValidate>
            <span>Recevez la liste des 178 idées de start-up tech !</span>
            <div className={InputWrapper}>
              <input type="email" name="EMAIL" placeholder="prenom.nom@email.fr" className={Input} />
              <input type="submit" value="Recevoir la checklist !" className={Button}/>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Hero;
